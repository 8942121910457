import styled from 'styled-components';
import media from '../../styled/media';

export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
  ${media.tablet`
    flex-direction: row;
    justify-content: space-around;
    justify-content: space-evenly;
  `}
`;

export const Item = styled.li`
  margin: 30px 0;
`;
