import { StaticImageData } from 'next/image';

import odloLogo from './images/brand-logos/odlo.svg';
import assosLogo from './images/brand-logos/assos.svg';
import holzweilerLogo from './images/brand-logos/holzweiler.svg';
import seaLogo from './images/brand-logos/sea.svg';
import californiacowboyLogo from './images/brand-logos/californiacowboy.svg';
import bonaparteLogo from './images/brand-logos/bonaparte.svg';
import paulandsharkLogo from './images/brand-logos/paulandshark.svg';
import cycologyLogo from './images/brand-logos/cycology.svg';
import headLogo from './images/brand-logos/head.svg';
import kerritsLogo from './images/brand-logos/kerrits.svg';
import shrimpsLogo from './images/brand-logos/shrimps.svg';
import hmThailandLogo from './images/brand-logos/hm-thailand.svg';
import hmIndonesiaLogo from './images/brand-logos/hm-indonesia.svg';
import malenaLogo from './images/brand-logos/malena.svg';
import heimstoneLogo from './images/brand-logos/heimstone.svg';
import companysLogo from './images/brand-logos/companys.svg';
import sainttropezLogo from './images/brand-logos/sainttropez.svg';
import soakedLogo from './images/brand-logos/soaked.svg';
import matiniqueLogo from './images/brand-logos/matinique.svg';
import inwearLogo from './images/brand-logos/inwear.svg';
import ichiLogo from './images/brand-logos/ichi.svg';
import byoungLogo from './images/brand-logos/byoung.svg';
import fransaLogo from './images/brand-logos/fransa.svg';
import pulzjeansLogo from './images/brand-logos/pulzjeans.svg';
import pocLogo from './images/brand-logos/poc.svg';
import karllagerfeldLogo from './images/brand-logos/karllagerfeld.svg';
import dressmannLogo from './images/brand-logos/dressmann.svg';
import bikbokLogo from './images/brand-logos/bikbok.svg';
import maiersportsLogo from './images/brand-logos/maiersports.svg';
import gonsoLogo from './images/brand-logos/gonso.svg';
import giantLogo from './images/brand-logos/giant.svg';
import aguLogo from './images/brand-logos/agu.svg';
import atomicLogo from './images/brand-logos/atomic.svg';
import baumundpferdgartenLogo from './images/brand-logos/baumundpferdgarten.svg';
import livLogo from './images/brand-logos/liv.svg';
import krugerLogo from './images/brand-logos/kruger.svg';
import pedalmafiaLogo from './images/brand-logos/pedalmafia.svg';
import yumikimLogo from './images/brand-logos/yumikim.svg';
import ortovoxLogo from './images/brand-logos/ortovox.svg';
import saylorLogo from './images/brand-logos/saylor.svg';
import gauge81Logo from './images/brand-logos/gauge81.svg';
import sanvtLogo from './images/brand-logos/sanvt.svg';
import peakperformanceLogo from './images/brand-logos/peakperformance.svg';
import bjornborgLogo from './images/brand-logos/bjornborg.svg';
import ecoalfLogo from './images/brand-logos/ecoalf.svg';
import renattandgoLogo from './images/brand-logos/renattandgo.svg';
import cubusLogo from './images/brand-logos/cubus.svg';
import carlingsLogo from './images/brand-logos/carlings.svg';

import odloBg from './images/brand-logos-bg/odlo.jpg';
import assosBg from './images/brand-logos-bg/assos.jpg';
import holzweilerBg from './images/brand-logos-bg/holzweiler.jpg';
import seaBg from './images/brand-logos-bg/sea.jpg';
import californiacowboyBg from './images/brand-logos-bg/californiacowboy.jpg';
import bonaparteBg from './images/brand-logos-bg/bonaparte.jpg';
import paulandsharkBg from './images/brand-logos-bg/paulandshark.jpg';
import cycologyBg from './images/brand-logos-bg/cycology.jpg';
import headBg from './images/brand-logos-bg/head.jpg';
import kerritsBg from './images/brand-logos-bg/kerrits.jpg';
import shrimpsBg from './images/brand-logos-bg/shrimps.jpg';
import hmThailandBg from './images/brand-logos-bg/hm-thailand.jpg';
import hmIndonesiaBg from './images/brand-logos-bg/hm-indonesia.jpg';
import malenaBg from './images/brand-logos-bg/malena.jpg';
import heimstoneBg from './images/brand-logos-bg/heimstone.jpg';
import companysBg from './images/brand-logos-bg/companys.jpg';
import sainttropezBg from './images/brand-logos-bg/sainttropez.jpg';
import soakedBg from './images/brand-logos-bg/soaked.jpg';
import matiniqueBg from './images/brand-logos-bg/matinique.jpg';
import inwearBg from './images/brand-logos-bg/inwear.jpg';
import ichiBg from './images/brand-logos-bg/ichi.jpg';
import byoungBg from './images/brand-logos-bg/byoung.jpg';
import fransaBg from './images/brand-logos-bg/fransa.jpg';
import pulzjeansBg from './images/brand-logos-bg/pulzjeans.jpg';
import pocBg from './images/brand-logos-bg/poc.jpg';
import karllagerfeldBg from './images/brand-logos-bg/karllagerfeld.jpg';
import dressmannBg from './images/brand-logos-bg/dressmann.jpg';
import bikbokBg from './images/brand-logos-bg/bikbok.jpg';
import maiersportsBg from './images/brand-logos-bg/maiersports.jpg';
import gonsoBg from './images/brand-logos-bg/gonso.jpg';
import giantBg from './images/brand-logos-bg/giant.jpg';
import aguBg from './images/brand-logos-bg/agu.jpg';
import atomicBg from './images/brand-logos-bg/atomic.jpg';
import baumundpferdgartenBg from './images/brand-logos-bg/baumundpferdgarten.jpg';
import livBg from './images/brand-logos-bg/liv.jpg';
import krugerBg from './images/brand-logos-bg/kruger.jpg';
import pedalmafiaBg from './images/brand-logos-bg/pedalmafia.jpg';
import yumikimBg from './images/brand-logos-bg/yumikim.jpg';
import ortovoxBg from './images/brand-logos-bg/ortovox.jpg';
import saylorBg from './images/brand-logos-bg/saylor.jpg';
import gauge81Bg from './images/brand-logos-bg/gauge81.jpg';
import sanvtBg from './images/brand-logos-bg/sanvt.jpg';
import peakPerformanceBg from './images/brand-logos-bg/peakperformance.jpg';
import bjornborgBg from './images/brand-logos-bg/bjornborg.jpg';
import ecoalfBg from './images/brand-logos-bg/ecoalf.jpg';
import renattandgoBg from './images/brand-logos-bg/renattandgo.jpg';
import cubusBg from './images/brand-logos-bg/cubus.jpg';
import carlingsBg from './images/brand-logos-bg/carlings.jpg';

type Brand = {
  name: string;
  url: string;
  logo: StaticImageData;
  background: StaticImageData;
};

const brands: Brand[] = [
  {
    name: 'Peak Performance',
    url: 'https://www.peakperformance.com/',
    logo: peakperformanceLogo,
    background: peakPerformanceBg,
  },
  {
    name: 'H&M Thailand',
    url: 'https://th.hm.com/',
    logo: hmThailandLogo,
    background: hmThailandBg,
  },
  {
    name: 'Karl Lagerfeld',
    url: 'https://www.karl.com/',
    logo: karllagerfeldLogo,
    background: karllagerfeldBg,
  },
  {
    name: 'Odlo',
    url: 'https://www.odlo.com/',
    logo: odloLogo,
    background: odloBg,
  },
  {
    name: 'Paul & Shark',
    url: 'https://paulandshark.com/',
    logo: paulandsharkLogo,
    background: paulandsharkBg,
  },
  {
    name: 'Head',
    url: 'https://www.head.com/',
    logo: headLogo,
    background: headBg,
  },
  {
    name: "Bon'a Parte",
    url: 'https://www.bonaparteshop.com/',
    logo: bonaparteLogo,
    background: bonaparteBg,
  },
  {
    name: 'Dressmann',
    url: 'https://dressmann.com/',
    logo: dressmannLogo,
    background: dressmannBg,
  },
  {
    name: 'Bik Bok',
    url: 'https://bikbok.com/',
    logo: bikbokLogo,
    background: bikbokBg,
  },
  {
    name: 'poc',
    url: 'https://www.pocsports.com/',
    logo: pocLogo,
    background: pocBg,
  },
  {
    name: 'bjornborg',
    url: 'https://www.bjornborg.com/',
    logo: bjornborgLogo,
    background: bjornborgBg,
  },
  {
    name: 'Saint Tropez',
    url: 'https://www.sainttropez.com/',
    logo: sainttropezLogo,
    background: sainttropezBg,
  },
  {
    name: 'Giant',
    url: 'https://www.giant-bicycles.com/',
    logo: giantLogo,
    background: giantBg,
  },
  {
    name: 'H&M Indonesia',
    url: 'https://id.hm.com/',
    logo: hmIndonesiaLogo,
    background: hmIndonesiaBg,
  },
  {
    name: 'Agu',
    url: 'https://agu.com/',
    logo: aguLogo,
    background: aguBg,
  },
  {
    name: 'Atomic',
    url: 'https://www.atomic.com/',
    logo: atomicLogo,
    background: atomicBg,
  },
  {
    name: 'Assos',
    url: 'https://www.assos.com/',
    logo: assosLogo,
    background: assosBg,
  },
  {
    name: 'Holzweiler',
    url: 'https://holzweileroslo.com/',
    logo: holzweilerLogo,
    background: holzweilerBg,
  },
  {
    name: 'Baum und Pferdgarten',
    url: 'https://www.baumundpferdgarten.com/',
    logo: baumundpferdgartenLogo,
    background: baumundpferdgartenBg,
  },
  {
    name: 'Companys',
    url: 'https://www.companys.com/',
    logo: companysLogo,
    background: companysBg,
  },
  {
    name: 'Liv',
    url: 'https://www.liv-cycling.com/',
    logo: livLogo,
    background: livBg,
  },
  {
    name: 'Matinique',
    url: 'https://www.matinique.com/',
    logo: matiniqueLogo,
    background: matiniqueBg,
  },
  {
    name: 'InWear',
    url: 'https://www.inwear.com/',
    logo: inwearLogo,
    background: inwearBg,
  },
  {
    name: 'Malena',
    url: 'https://malenalingerie.it/',
    logo: malenaLogo,
    background: malenaBg,
  },
  {
    name: 'Sea',
    url: 'https://sea-ny.com/',
    logo: seaLogo,
    background: seaBg,
  },
  {
    name: 'Heimstone',
    url: 'https://heimstone-paris.myshopify.com/collections/coats-jackets/',
    logo: heimstoneLogo,
    background: heimstoneBg,
  },
  {
    name: 'Kruger',
    url: 'https://www.krueger-dirndl.de/',
    logo: krugerLogo,
    background: krugerBg,
  },
  {
    name: 'Pedal Mafia',
    url: 'https://usa.pedalmafia.cc/',
    logo: pedalmafiaLogo,
    background: pedalmafiaBg,
  },
  {
    name: 'Ecoalf',
    url: 'https://ecoalf.com/',
    logo: ecoalfLogo,
    background: ecoalfBg,
  },
  {
    name: 'California Cowboy',
    url: 'https://shop.californiacowboy.com/',
    logo: californiacowboyLogo,
    background: californiacowboyBg,
  },
  {
    name: 'Kerrits',
    url: 'https://kerrits.com/',
    logo: kerritsLogo,
    background: kerritsBg,
  },
  {
    name: 'Shrimps',
    url: 'https://shrimps.com/',
    logo: shrimpsLogo,
    background: shrimpsBg,
  },
  {
    name: 'Cycology',
    url: 'https://www.cycologygear.com/',
    logo: cycologyLogo,
    background: cycologyBg,
  },
  {
    name: 'Ortovox',
    url: 'https://www.ortovox.com/',
    logo: ortovoxLogo,
    background: ortovoxBg,
  },
  {
    name: 'Renatta&Go',
    url: 'https://www.renattandgo.com/',
    logo: renattandgoLogo,
    background: renattandgoBg,
  },
  {
    name: 'Soaked in luxury',
    url: 'https://www.soakedinluxury.com/',
    logo: soakedLogo,
    background: soakedBg,
  },
  {
    name: 'Cubus',
    url: 'https://cubus.com/',
    logo: cubusLogo,
    background: cubusBg,
  },
  {
    name: 'Sanvt',
    url: 'https://sanvt.com/',
    logo: sanvtLogo,
    background: sanvtBg,
  },
  {
    name: 'Ichi',
    url: 'https://www.ichi.biz/',
    logo: ichiLogo,
    background: ichiBg,
  },
  {
    name: 'b.young',
    url: 'https://www.byoung.com/',
    logo: byoungLogo,
    background: byoungBg,
  },
  {
    name: 'Fransa',
    url: 'https://www.fransa.com/',
    logo: fransaLogo,
    background: fransaBg,
  },
  {
    name: 'Pulz Jeans',
    url: 'https://www.pulzjeans.com/',
    logo: pulzjeansLogo,
    background: pulzjeansBg,
  },
  {
    name: 'Maier Sports',
    url: 'https://www.maier-sports.com/',
    logo: maiersportsLogo,
    background: maiersportsBg,
  },
  {
    name: 'Gonso',
    url: 'https://gonso.de/',
    logo: gonsoLogo,
    background: gonsoBg,
  },
  {
    name: 'Yumi Kim',
    url: 'https://yumikim.com/',
    logo: yumikimLogo,
    background: yumikimBg,
  },
  {
    name: 'Gauge81',
    url: 'https://gauge81.com/',
    logo: gauge81Logo,
    background: gauge81Bg,
  },
  {
    name: 'Saylor',
    url: 'https://www.saylor.org/',
    logo: saylorLogo,
    background: saylorBg,
  },
  {
    name: 'Carlings',
    url: 'https://carlings.com/',
    logo: carlingsLogo,
    background: carlingsBg,
  },
];

export default brands;
