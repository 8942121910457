import { useState, useEffect } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import Partners from '../../ui/partners';
import Layout from '../../ui/layout';
import { Wrap, Button } from '../../styled';
import { TypeformButtonMain } from '../../components';
import {
  H2,
  Intro,
  IntroHeading,
  IntroDescription,
  IntroVideo,
  SizeEngine,
  SizeEngineInner,
  SizeEngineCell,
  SizeEngineDescription,
  WhatWeProvide,
  HowWeDeliver,
  DeliveredItems,
  DeliveredItem,
  DeliveredItemTitle,
  Demo,
  DemoImageContainer,
  DemoInner,
  PartnersContainer,
  Pricing,
  PricingCell,
  PricingDescription,
  PricingCardsContainer,
  PricingCards,
  PricingCard,
  PricingCardSecondary,
  PricingCardTitle,
  PricingCardList,
  PricingCardListItem,
  PricingCardButton,
  Ul,
  Li,
  BrandsGrid,
  BrandsGridCell,
  BrandsGridLogo,
  Brands,
  BrandsInner,
  BrandsDescriptionText,
  BrandsDescriptionButton,
} from './styled';

import sizeEngine from './images/size-engine.png';
import kpi from './images/kpi.png';
import whatWeProvide1 from './images/what-we-provide-1.svg';
import whatWeProvide2 from './images/what-we-provide-2.svg';
import whatWeProvide3 from './images/what-we-provide-3.svg';
import whatWeProvide4 from './images/what-we-provide-4.svg';
import demo from './images/demo.jpg';

import brands from './brands';

const Home = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const isTablet = useMediaQuery({ minWidth: 768 });

  const videoSrc =
    isMounted && isTablet
      ? '/images/retailers/video_desktop.mp4'
      : '/images/retailers/video_adaptive.mp4';

  return (
    <Layout>
      <Head>
        <meta name="image" property="og:image" content={sizeEngine.src} />
      </Head>
      <Intro>
        <Wrap>
          <IntroHeading>SIZE-AS-A-SERVICE</IntroHeading>
          <IntroDescription>
            We help apparel retailers increase online conversions, reduce
            returns and optimize production with the power of AI and data.
          </IntroDescription>
          <IntroVideo src={videoSrc} autoPlay loop muted playsInline />
        </Wrap>
      </Intro>
      <Brands>
        <Wrap>
          <BrandsInner>
            <H2>You’re in good company</H2>
            <BrandsDescriptionText>
              Join hundreds of brands globally with our award-winning platform
            </BrandsDescriptionText>
            <BrandsDescriptionButton>
              <Button as={TypeformButtonMain}>Shall we talk?</Button>
            </BrandsDescriptionButton>
            <BrandsGrid>
              {brands.map(({ name, logo, background, url }) => (
                <Link key={name} href={url} passHref legacyBehavior>
                  <BrandsGridCell target="_blank" rel="noopener noreferrer">
                    <Image
                      src={background}
                      alt=""
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                    <BrandsGridLogo>
                      <Image src={logo} alt={name} />
                    </BrandsGridLogo>
                  </BrandsGridCell>
                </Link>
              ))}
            </BrandsGrid>
          </BrandsInner>
        </Wrap>
      </Brands>
      <SizeEngine>
        <Wrap>
          <H2>
            Minimize returns. Maximize conversions. Personalize your brand
            experience.
          </H2>
          <SizeEngineInner>
            <SizeEngineCell>
              <Image
                src={sizeEngine}
                width={590}
                height={447}
                style={{ objectFit: 'contain' }}
                alt=""
              />
            </SizeEngineCell>
            <SizeEngineCell>
              <H2>SizeEngine™ AI Platform</H2>
              <SizeEngineDescription>
                <p>
                  Our SizeEngine™ increases personalization and consumer
                  confidence which leads to an increase in online conversions, a
                  reduction in return rates and increase profitability.
                </p>
                <p>
                  Lowering return rates has a direct impact on sustainability
                  efforts resulting in less transportation, reconditioning,
                  waste and better inventory management and fulfilment costs.
                </p>
                <p>
                  Our advanced analytics covering customers anthropometric
                  measurements and behavior enables manufacturing optimization.
                </p>
                <p>Want to learn more?</p>
              </SizeEngineDescription>
              <Button as={TypeformButtonMain}>Schedule a demo</Button>
            </SizeEngineCell>
          </SizeEngineInner>
        </Wrap>
      </SizeEngine>
      <SizeEngine>
        <Wrap>
          <SizeEngineInner>
            <SizeEngineCell>
              <Image src={kpi} width="552" height="416" alt="" />
            </SizeEngineCell>
            <SizeEngineCell>
              <H2>KPIs</H2>
              <Ul>
                <Li>Conversion rate: +15%</Li>
                <Li>Average Order Value: +15% to +45%</Li>
                <Li rotated>Returns Decrease: -55%</Li>
                <Li rotated>Cart Abandonment: -10% to -15%</Li>
              </Ul>
            </SizeEngineCell>
          </SizeEngineInner>
        </Wrap>
      </SizeEngine>
      <Wrap>
        <Pricing>
          <PricingCell>
            <PricingDescription>
              <H2>
                Transparent and affordable pricing for businesses of all sizes
              </H2>
              <p>Compatible with any e-commerce platform</p>
            </PricingDescription>
          </PricingCell>
          <PricingCell>
            <PricingCardsContainer>
              <PricingCards>
                <PricingCard>
                  <PricingCardTitle>EyeFitU SaaS solution</PricingCardTitle>
                  <PricingCardList>
                    {[
                      'Low-complexity integration with dedicated team',
                      'Low-cost trial period',
                      'Affordable setup fee',
                      'Billing only for users using the service',
                    ].map((item) => (
                      <PricingCardListItem key={item}>
                        {item}
                      </PricingCardListItem>
                    ))}
                  </PricingCardList>
                  <PricingCardButton as={TypeformButtonMain}>
                    Request a quote
                  </PricingCardButton>
                </PricingCard>
                <PricingCardSecondary>
                  <PricingCardTitle>
                    Other solutions on the market
                  </PricingCardTitle>
                  <PricingCardList>
                    {[
                      'Slow and complex integration',
                      'Costly setup fee',
                      'Revenue share',
                      'Extra fees',
                      'High fixed price',
                    ].map((item) => (
                      <PricingCardListItem key={item}>
                        {item}
                      </PricingCardListItem>
                    ))}
                  </PricingCardList>
                </PricingCardSecondary>
              </PricingCards>
            </PricingCardsContainer>
          </PricingCell>
        </Pricing>
      </Wrap>
      <WhatWeProvide>
        <Wrap>
          <H2>What We Provide</H2>
          <p>
            State of the art technology with Swiss precision that can be
            implemented & generate ROI within only 7 days.
          </p>
          <DeliveredItems>
            <DeliveredItem>
              <Image src={whatWeProvide1} width="128" height="128" alt="" />
              <p>Increase your conversions, sales and average basket sizes</p>
            </DeliveredItem>
            <DeliveredItem>
              <Image src={whatWeProvide2} width="128" height="128" alt="" />
              <p>Reduce customer returns</p>
            </DeliveredItem>
            <DeliveredItem>
              <Image src={whatWeProvide3} width="128" height="128" alt="" />
              <p>Reduce your carbon footprint</p>
            </DeliveredItem>
            <DeliveredItem>
              <Image src={whatWeProvide4} width="128" height="128" alt="" />
              <p>Insightful data (size and behavioural analytics)</p>
            </DeliveredItem>
          </DeliveredItems>
          <p>Want to know more?</p>
          <Button as={TypeformButtonMain}>Get more info</Button>
        </Wrap>
      </WhatWeProvide>
      <HowWeDeliver>
        <Wrap>
          <H2>How We Deliver</H2>
          <p>
            Our solution is powered by Machine Learning and enhanced by Advanced
            Analytics to drive your success.
            <br />
            Our unique SaaS platform has direct integrations with our partners
            Oracle, Shopify and WooCommerce.
          </p>
          <DeliveredItems>
            <DeliveredItem>
              <DeliveredItemTitle>SizeFinder™</DeliveredItemTitle>
              <p>Customer get size recommendations through quick onboarding</p>
            </DeliveredItem>
            <DeliveredItem>
              <DeliveredItemTitle>SizeVisualizer™</DeliveredItemTitle>
              <p>Personalized imaging of garment fit</p>
            </DeliveredItem>
            <DeliveredItem>
              <DeliveredItemTitle>Fit Preference</DeliveredItemTitle>
              <p>Visual engagement technology</p>
            </DeliveredItem>
            <DeliveredItem>
              <DeliveredItemTitle>Advanced Analytics</DeliveredItemTitle>
              <p>
                Aggregated data on customers including behavioural analytics
              </p>
            </DeliveredItem>
          </DeliveredItems>
          <Link href="/how-it-works" passHref legacyBehavior>
            <Button>how it works</Button>
          </Link>
        </Wrap>
      </HowWeDeliver>
      <PartnersContainer>
        <Wrap>
          <H2>Partners</H2>
          <Partners />
          <Button as={TypeformButtonMain}>Get more info</Button>
        </Wrap>
      </PartnersContainer>
      <Demo>
        <DemoImageContainer>
          <Image src={demo} alt="" style={{ objectFit: 'contain' }} />
        </DemoImageContainer>
        <Wrap>
          <DemoInner>
            <div>
              <H2>Book a Demo</H2>
              <Button as={TypeformButtonMain}>Let’s talk</Button>
            </div>
          </DemoInner>
        </Wrap>
      </Demo>
    </Layout>
  );
};

export default Home;
