import styled from 'styled-components';
import media from '../../styled/media';
import { ButtonPlain, Button } from '../../styled';

export const H2 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  margin: 20px 0;
  font-weight: 400;
  ${media.tablet`
    font-size: 34px;
    line-height: 48px;
  `}
`;

export const Intro = styled.div`
  text-align: center;
  padding: 0 0 80px;
`;

export const IntroHeading = styled.h1`
  color: #4a4a4a;
  font-size: 30px;
  line-height: 32px;
  font-weight: 400;
  margin: 24px 0;
  ${media.tablet`
    font-size: 48px;
    line-height: 70px;
  `}
`;

export const IntroDescription = styled.p`
  ${media.tablet`
    font-size: 26px;
    line-height: 39px;
  `}
  max-width: 870px;
  margin: 24px auto;
`;

export const IntroVideo = styled.video`
  width: 100%;
`;

export const SizeEngine = styled.div`
  background: #f5f5f5;
  padding: 40px 0;
  ${media.tablet`
    padding: 80px 0;
  `}
  & + & {
    padding-top: 0;
  }
`;

export const SizeEngineInner = styled.div`
  margin-top: 40px;
  text-align: center;
  ${media.tablet`
    display: flex;
    align-items: center;
    text-align: inherit;
  `}
`;

export const SizeEngineCell = styled.div`
  ${media.tablet`
    width: 50%;
    padding: 0 40px;
  `}
`;

export const SizeEngineDescription = styled.div`
  margin-bottom: 40px;
  ${media.tablet`
    font-size: 18px;
  `}
`;

export const Ul = styled.ul`
  text-align: left;
  list-style-image: url('/images/home/checkmark-green.svg');
  li {
    margin: 12px 0 12px 22px;
    ${media.tablet`
      padding-left: 10px;
    `}
  }
`;

const getLiIcon = (rotated?: boolean) =>
  rotated
    ? '/images/home/checkmark-green-rotated.svg'
    : '/images/home/checkmark-green.svg';

export const Li = styled.li<{ rotated?: boolean }>`
  margin: 12px 0 12px 22px;
  ${media.tablet`
    padding-left: 10px;
  `}

  list-style-image: url(${({ rotated }) => getLiIcon(rotated)});

  &::marker {
    color: red;
    border: 1px solid red;
  }
`;

export const WhatWeProvide = styled.div`
  background: #f5f5f5;
  text-align: center;
  padding: 80px 0;
`;

export const OurClients = styled.div`
  text-align: center;
  padding: 80px 0;
`;

export const DeliveredItemTitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  margin: 20px 0;
  color: #cb2027;
  ${media.tablet`
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const HowWeDeliver = styled.div`
  padding: 80px 0;
  text-align: center;
`;

export const DeliveredItems = styled.ul`
  margin: 20px 0;
  ${media.tablet`
    margin: 60px 0;
    display: flex;
  `}
`;

export const DeliveredItem = styled.li`
  padding: 20px 0;
  & + & {
    border-top: 1px solid #dcdcdc;
  }
  ${media.tablet`
    flex: 1;
    padding: 0 20px;
    & + & {
      border-top: none;
      border-left: 1px solid #dcdcdc;
    }
  `}
`;

export const Demo = styled.div`
  position: relative;
`;

export const DemoImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 60%;
  display: flex;
  ${media.tablet`
    height: 100%;
    width: 70%;
    right: 0;
  `}
`;

export const DemoInner = styled.div`
  padding: 40px 0;
  height: 467px;
  text-align: center;
  ${media.tablet`
    height: 611px;
    text-align: initial;
    display: flex;
    align-items: center;
  `}
`;

export const Slide = styled.div`
  position: relative;
  height: 360px;
  outline: none;
  ${media.tablet`
    height: 600px;
  `}
`;

export const SlideOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const SlideBtn = styled(ButtonPlain)`
  position: relative;
  top: 260px;
  ${media.tablet`
    top: 440px;
  `}
`;

export const PartnersContainer = styled.div`
  background: #f5f5f5;
  text-align: center;
  padding: 40px 0;
  ${media.tablet`
    padding: 76px 0 96px;
  `}
`;

export const CarouselFooter = styled.div`
  text-align: center;
  margin: 48px 0;
`;

export const Pricing = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${media.PC`
    padding: 96px 0;
    flex-direction: row;
  `}
`;

export const PricingCell = styled.div`
  flex: 1;
  max-width: 100%;
`;

export const PricingDescription = styled.div`
  text-align: center;
  max-width: 540px;
  margin-bottom: 40px;
  ${media.PC`
    text-align: left;
    padding-right: 20px;
    margin-bottom: 0;
  `}
`;

export const PricingCardsContainer = styled.div`
  margin: 0 -20px;
  ${media.tablet`
    margin: 0;
  `}
`;

export const PricingCards = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  overflow: auto;
`;

export const PricingCard = styled.div`
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 220px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${media.tablet`
    width: 360px;
    padding: 40px 32px;
  `}
`;

export const PricingCardTitle = styled.p`
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  ${media.tablet`
    font-size: 24px;
    line-height: 33px;
  `}
`;

export const PricingCardButton = styled(Button)`
  padding: 12px;
  margin-top: 16px;
  ${media.tablet`
    padding: 12px 48px;
    align-self: center;
  `}
`;

export const PricingCardList = styled.ul`
  padding-left: 30px;
`;

export const PricingCardListItem = styled.li`
  list-style-image: url('/images/retailers/icons/bullet-green.svg');
  margin: 16px 0;
  ${media.tablet`
    font-size: 18px;
    line-height: 27px;
    margin: 18px 0;
  `}
`;

export const PricingCardSecondary = styled(PricingCard)`
  margin-left: 8px;
  ${media.tablet`
    margin-left: -10px;
    width: 230px;
    z-index: -1;
  `}
  ${PricingCardTitle} {
    font-size: 16px;
    line-height: 22px;
    ${media.tablet`
      font-size: 18px;
      line-height: 25px;
    `}
  }
  ${PricingCardListItem} {
    list-style-image: url('/images/retailers/icons/bullet-gray.svg');
    font-size: 14px;
    line-height: 21px;
    margin: 14px 0;
  }
`;

export const Brands = styled.div`
  padding-bottom: 96px;
`;

export const BrandsInner = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const BrandsDescriptionText = styled.p`
  font-size: 18px;
  line-height: 27px;
  margin: 0 0 24px;
  ${media.tablet`
    margin-bottom: 72px;
  `}
`;

export const BrandsDescriptionButton = styled.div`
  margin-bottom: 40px;
  ${media.tablet`
    order: 1;
    margin: 72px 0 0;
  `}
`;

export const BrandsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  ${media.tablet`
    grid-template-columns: repeat(6, 1fr);
  `}
`;

export const BrandsGridLogo = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 0.2s ease;
  img {
    transition: filter 0.2s ease;
    filter: brightness(0.5);
  }
`;

export const BrandsGridCell = styled.a`
  outline: 1px solid #b4b4b4;
  position: relative;
  height: 130px;
  &:hover {
    ${BrandsGridLogo} {
      background-color: rgba(255, 255, 255, 0);
      img {
        filter: brightness(1);
      }
    }
  }
`;
