import Image from 'next/image';
import { List, Item } from './styled';

import shopify from './images/shopify.png';
import microsoft from './images/microsoft.png';
import oracle from './images/oracle.png';
import woocommerce from './images/woocommerce.png';

const Partners = () => (
  <List>
    <Item>
      <a
        href="https://apps.shopify.com/eyefitu-widget"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={shopify}
          alt="Shopify"
          width="195"
          height="61"
          style={{ objectFit: 'contain' }}
        />
      </a>
    </Item>
    <Item>
      <a
        href="https://appsource.microsoft.com/en-us/marketplace/partner-dir/abd482a4-67c0-4252-a625-40edcbc71945/overview"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={microsoft}
          alt="Microsoft"
          width="266"
          height="92"
          style={{ objectFit: 'contain' }}
        />
      </a>
    </Item>
    <Item>
      <a
        href="https://cloudmarketplace.oracle.com/marketplace/en_US/listing/62328897"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={oracle}
          alt="Oracle"
          width="202"
          height="42"
          style={{ objectFit: 'contain' }}
        />
      </a>
    </Item>
    <Item>
      <Image
        src={woocommerce}
        alt="WooCommerce"
        width="200"
        height="51"
        style={{ objectFit: 'contain' }}
      />
    </Item>
  </List>
);

export default Partners;
